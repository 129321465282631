import { ChakraProvider, theme } from "@chakra-ui/react";

import App from "./App";
import React from 'react';
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
    <footer className="footer" style={{
      "position": "fixed",
      "bottom": 0,
      "width": "100%",
      "padding": "8px",
      "margin": "auto",
      //@ts-ignore
      "text-align": "center",
      "border-radius": "20px",
      "background": "rgba(253, 224, 71, 0.3)",
      "border-style": "solid",
      "border-width": "1px",
      "border-color": "#FACC15"
      //@ts-ignore,
    }}>This is a demo application - if you encounter any issues join our <a href="https://discord.bundlr.network/" style={{ "text-decoration": "underline" }}>Discord</a></footer>
  </React.StrictMode >,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
