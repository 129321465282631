import * as nearAPI from "near-api-js";

import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Container,
  HStack,
  Icon,
  Input,
  Menu,
  Text,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { SVGProps, useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type * as _ from "arconnect";

import AdvancedOptionsModal from "./components/AdvancedOptions/AdvancedOptions";
import BigNumber from "bignumber.js";
import { Button } from "@chakra-ui/button";
import { HiMegaphone } from "react-icons/hi2";
import { ImCogs } from "react-icons/im";
import TokenSelectModal from "./components/TokenSelectModal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { WalletConnection } from "near-api-js";
import WalletModal from "./components/WalletModal";
import { WebBundlr } from "@bundlr-network/client";

// @ts-ignore
import fileReaderStream from "filereader-stream";
import { providers } from "ethers";
import { sleep } from "@bundlr-network/client/build/esm/common/utils";


declare var window: any; // TODO: specifically extend type to valid injected objects.
const PhantomWalletAdapter =
  require("@solana/wallet-adapter-phantom/lib/cjs/index").PhantomWalletAdapter;
const { keyStores, connect } = nearAPI;

const BundlrIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={42}
    viewBox="0 0 36 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.0291 7.29544C32.0881 6.72879 31.0111 6.42763 29.9127 6.42392C29.7353 6.42392 29.5529 6.42392 29.3755 6.42392C28.5608 6.50529 27.7695 6.74269 27.0445 7.12318L23.8166 8.87636L22.2964 9.69218L11.3154 15.57C10.3451 16.0864 9.53385 16.8574 8.96871 17.8001C8.40358 18.7428 8.10596 19.8217 8.1078 20.9208V26.226L12.1617 24.0573V20.9208C12.1626 20.556 12.2619 20.1983 12.4492 19.8852C12.6365 19.5722 12.9048 19.3155 13.2258 19.1422L22.2964 14.288L23.8166 13.4773L27.8705 11.3085L28.96 10.7259C29.0954 10.6499 29.2402 10.5919 29.3907 10.5536C29.5549 10.503 29.7257 10.4774 29.8975 10.4776C30.2654 10.4809 30.6257 10.5823 30.9413 10.7714C31.2416 10.9483 31.4901 11.2012 31.6617 11.5044C31.8334 11.8077 31.9222 12.1508 31.9193 12.4993V23.9104C31.9184 24.2751 31.8191 24.6329 31.6318 24.9459C31.4445 25.259 31.1762 25.5156 30.8552 25.6889L12.1617 35.6963V32.1038L8.1078 34.2522V38.8633C8.10756 39.234 8.20348 39.5984 8.38619 39.921C8.56889 40.2435 8.83213 40.5132 9.1502 40.7036C9.46828 40.894 9.83031 40.9987 10.2009 41.0074C10.5715 41.0161 10.9381 40.9286 11.2648 40.7533L32.7707 29.2409C33.7383 28.7265 34.5479 27.9589 35.1129 27.0199C35.6779 26.081 35.977 25.0062 35.9783 23.9104V12.4993C35.9829 11.4502 35.7131 10.418 35.1958 9.5052C34.6785 8.5924 33.9316 7.83062 33.0291 7.29544Z"
      fill="url(#paint0_linear_27_1774)"
    />
    <path
      d="M23.8166 15.2002V18.3367C23.8157 18.7015 23.7164 19.0593 23.5291 19.3723C23.3418 19.6853 23.0735 19.942 22.7525 20.1153L13.6819 24.9695L12.1617 25.7853L8.10782 27.954L6.58761 28.7647L4.05393 30.1227V15.3472C4.05484 14.9824 4.15419 14.6246 4.34148 14.3116C4.52877 13.9986 4.79707 13.7419 5.11808 13.5686L20.8269 5.15222C21.1151 4.99126 21.4393 4.90585 21.7694 4.90394C22.1373 4.90728 22.4977 5.00871 22.8133 5.19781C23.1136 5.37471 23.362 5.62752 23.5337 5.93081C23.7054 6.2341 23.7942 6.57721 23.7913 6.9257V7.15372L26.2996 5.81094C26.7194 5.58735 27.1587 5.4025 27.6121 5.25864C27.1971 3.77078 26.2242 2.50029 24.896 1.71169C23.9979 1.16738 22.9731 0.867436 21.9233 0.841658C20.8734 0.815881 19.8351 1.06514 18.9114 1.56473L3.20262 9.98619C2.23174 10.5041 1.42049 11.277 0.856181 12.2216C0.291873 13.1662 -0.00413018 14.2468 4.35348e-05 15.3472V33.3099C-0.000191554 33.6806 0.0957277 34.045 0.278433 34.3676C0.461138 34.6901 0.724377 34.9598 1.04245 35.1502C1.36052 35.3407 1.72255 35.4453 2.09318 35.4541C2.4638 35.4628 2.83035 35.3752 3.15702 35.1999L6.58761 33.3657L8.10782 32.5499L12.1617 30.3811L13.6819 29.5654L24.6629 23.6876C25.6336 23.1716 26.4452 22.4007 27.0103 21.4579C27.5755 20.515 27.8729 19.436 27.8705 18.3367V13.0315L23.8166 15.2002Z"
      fill="url(#paint1_linear_27_1774)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_27_1774"
        x1="22.0431"
        y1="6.42392"
        x2="22.0431"
        y2="41.008"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="black" />
        <stop offset={1} stopColor="black" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_27_1774"
        x1="13.9353"
        y1="0.839844"
        x2="13.9353"
        y2="35.4546"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="black" />
        <stop offset={1} stopColor="black" />
      </linearGradient>
    </defs>
  </svg>
);

function App() {
  const defaultCurrency = "Select a Currency";
  const defaultSelection = "Select a Provider";
  const [currency, setCurrency] = React.useState<string>(defaultCurrency);
  const [address, setAddress] = React.useState<string>();
  const [selection, setSelection] = React.useState<string>(defaultSelection);
  const [balance, setBalance] = React.useState<string>();
  const [mimeType, setMimeType] = React.useState<string>();
  const [imgStream, setImgStream] = React.useState<ReadableStream>();
  const [price, setPrice] = React.useState<BigNumber>();
  const [size, setSize] = React.useState<number>();
  const [bundler, setBundler] = React.useState<WebBundlr>();
  const [totalUploaded, setTotalUploaded] = React.useState<number>(0);
  const [lastUploadId, setLastUploadId] = React.useState<string>();
  const [bundlerHttpAddress, setBundlerAddress] = React.useState<string>(
    "https://node1.bundlr.network"
  );

  const [rpcUrl, setRpcUrl] = React.useState<string>();
  const [contractAddress, setContractAddress] = React.useState<string>();
  const [devMode, setDevMode] = React.useState<boolean>(false);
  const [chainChange, setChainChange] = React.useState<boolean>(true);

  const [fundAmount, setFundingAmount] = React.useState<string>();
  const [withdrawAmount, setWithdrawAmount] = React.useState<string>();
  const [provider, setProvider] = React.useState<any>();

  const toast = useToast();
  const intervalRef = React.useRef<number>();

  const clean = async () => {
    clearInterval(intervalRef.current);
    setBalance(undefined);
    setImgStream(undefined);
    setPrice(undefined);
    setBundler(undefined);
    setProvider(undefined);
    setAddress(undefined);
    setCurrency(defaultCurrency);
    setSelection(defaultSelection);
    setTotalUploaded(0);
  };

  const handleFileClick = () => {
    var fileInputEl = document.createElement("input");
    fileInputEl.type = "file";
    fileInputEl.accept = "*";
    fileInputEl.style.display = "none";
    document.body.appendChild(fileInputEl);
    fileInputEl.addEventListener("input", function (e) {
      handleUpload(e as any);
      document.body.removeChild(fileInputEl);
    });
    fileInputEl.click();
  };

  const handleUpload = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    let files = evt.target.files;
    if (files?.length !== 1) {
      throw new Error(
        `Invalid number of files (expected 1, got ${files?.length})`
      );
    }
    setMimeType(files[0]?.type ?? "application/octet-stream");
    setSize(files[0]?.size ?? 0);
    setImgStream(fileReaderStream(files[0]));
  };

  const handlePrice = async () => {
    if (size) {
      const price = await bundler?.utils.getPrice(currency as string, size);
      //@ts-ignore
      setPrice(price?.toString());
    }
  };

  const uploadFile = async () => {
    if (imgStream) {
      toast({ title: "Starting upload...", status: "info" });
      setTotalUploaded(0);
      setLastUploadId(undefined);
      await sleep(2_000); // sleep as this is all main thread (TODO: move to web worker?)
      const uploader = bundler?.uploader.chunkedUploader;
      uploader?.setBatchSize(4);
      uploader?.setChunkSize(5_000_000);
      uploader?.on("chunkUpload", (e) => {
        // toast({
        //   status: "info",
        //   title: "Upload progress",
        //   description: `${((e.totalUploaded / ((size ?? 0))) * 100).toFixed()}%`
        // });
        setTotalUploaded(e.totalUploaded);
      });

      uploader
        //@ts-ignore
        ?.uploadData(imgStream, {
          tags: [
            {
              name: "Content-Type",
              value: mimeType ?? "application/octet-stream",
            },
          ],
        })
        .then((res) => {
          setLastUploadId(res.data.id);
          toast({
            status:
              res?.status === 200 || res?.status === 201 ? "success" : "error",
            title:
              res?.status === 200 || res?.status === 201
                ? "Successful!"
                : `Unsuccessful! ${res?.status}`,
            description: res?.data.id
              ? `https://arweave.net/${res.data.id}`
              : undefined,
            duration: 15000,
            render: () => (
              <Alert
                status={
                  res?.status === 200 || res?.status === 201
                    ? "success"
                    : "error"
                }
                alignItems="start"
                borderRadius="md"
                boxShadow="lg"
                paddingEnd={8}
                textAlign="start"
                width="auto"
              >
                <AlertIcon />
                <a
                  href={`https://arweave.net/${res.data.id}`}
                  target="_blank"
                  rel="noreferrer"
                >{`https://arweave.net/${res.data.id}`}</a>
              </Alert>
            ),
          });
        })
        .catch((e) => {
          toast({ status: "error", title: `Failed to upload - ${e.message || e}` });
        });
    }
  };

  const fund = async () => {
    if (bundler && fundAmount) {
      toast({ status: "info", title: "Funding...", duration: 15000 });
      const value = parseInput(fundAmount);
      if (!value) return toast.closeAll();
      await bundler
        .fund(value)
        .then((res) => {
          toast({
            status: "success",
            title: `Funded ${res?.target}`,
            description: ` tx ID : ${res?.id}`,
            duration: 10000,
          });
          refreshBalance();
        })
        .catch((e) => {
          toast({
            status: "error",
            title: `Failed to fund - ${e.data?.message || e.message}`,
          });
        });
    }
  };

  const withdraw = async () => {
    if (bundler && withdrawAmount) {
      toast({ status: "info", title: "Withdrawing..", duration: 15000 });
      const value = parseInput(withdrawAmount);
      if (!value) return;
      await bundler
        .withdrawBalance(value)
        .then((data) => {
          toast({
            status: "success",
            title: `Withdrawal successful - ${(data as any)?.tx_id}`,
            duration: 5000,
          });
          refreshBalance();
        })
        .catch((err: any) => {
          toast({
            status: "error",
            title: "Withdrawal Unsuccessful!",
            description: err.message,
            duration: 5000,
          });
        });
    }
  };

  // field change event handlers

  const updateAddress = (evt: React.BaseSyntheticEvent) => {
    setBundlerAddress(evt.target.value);
  };

  const updateFundAmount = (evt: React.BaseSyntheticEvent) => {
    setFundingAmount(evt.target.value);
  };

  const updateWithdrawAmount = (evt: React.BaseSyntheticEvent) => {
    setWithdrawAmount(evt.target.value);
  };

  const connectWeb3 = async (connector: any) => {
    if (provider) {
      await clean();
    }
    const p = new providers.Web3Provider(connector);
    await p._ready();
    return p;
  };

  /**
   * Map of providers with initialisation code - c is the configuration object from currencyMap
   */
  const providerMap = {
    MetaMask: async (c: any) => {
      if (!window?.ethereum?.isMetaMask) return;
      await window.ethereum.enable();
      const provider = await connectWeb3(window.ethereum);
      const chainId = `0x${c.chainId.toString(16)}`;
      if (!chainChange) {
        return provider;
      }
      try {
        // additional logic for requesting a chain switch and conditional chain add.
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId }],
        });
      } catch (e: any) {
        if (e.code === 4902) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId,
                rpcUrls: c.rpcUrls,
                chainName: c.chainName,
              },
            ],
          });
        }
      }
      return provider;
    },
    WalletConnect: async (c: any) => {
      return await connectWeb3(await new WalletConnectProvider(c).enable());
    },
    Phantom: async (c: any) => {
      if (window.solana.isPhantom) {
        await window.solana.connect();
        const p = new PhantomWalletAdapter();
        await p.connect();
        return p;
      }
    },
    "wallet.near.org": async (c: any) => {
      const near = await connect(c);
      const wallet = new WalletConnection(near, "bundlr");
      if (!wallet.isSignedIn()) {
        toast({
          status: "info",
          title: "You are being redirected to authorize this application...",
        });
        window.setTimeout(() => {
          wallet.requestSignIn();
        }, 4000);
        // wallet.requestSignIn();
      } else if (
        !(await c.keyStore.getKey(wallet._networkId, wallet.getAccountId()))
      ) {
        toast({
          status: "warning",
          title:
            "Click 'Connect' to be redirected to authorize access key creation.",
        });
      }
      return wallet;
    },
    Arconnect: async (c: any) => {
      const arconnect = window.arweaveWallet as Window["arweaveWallet"]; // ACCESS_ARWEAVE_CONFIG is optional
      await arconnect.connect(["ACCESS_PUBLIC_KEY", "SIGN_TRANSACTION", "SIGNATURE", "ACCESS_ARWEAVE_CONFIG"], { name: "Demo App" });
      return arconnect;
    }
  } as any;

  const ethProviders = ["MetaMask", "WalletConnect"];

  const currencyMap = {
    solana: {
      symbol: "SOL",
      icon: "./assets/coins/currencies/c-sol.svg",
      providers: ["Phantom"],
      opts: {},
    },
    matic: {
      providers: ethProviders,
      symbol: "MATIC",
      icon: "./assets/coins/currencies/MATIC.svg",
      opts: {
        chainId: 137,
        chainName: "Polygon Mainnet",
        rpcUrls: ["https://polygon-rpc.com"],
      },
    },
    arbitrum: {
      providers: ethProviders,
      symbol: "ARB",
      icon: "./assets/coins/currencies/ArbETH.svg",
      opts: {
        chainName: "Arbitrum One",
        chainId: 42161,
        rpcUrls: ["https://arb1.arbitrum.io/rpc"],
      },
    },
    bnb: {
      providers: ethProviders,
      symbol: "BNB",
      icon: "./assets/coins/currencies/BNB.svg",
      opts: {
        chainName: "Binance Smart Chain",
        chainId: 56,
        rpcUrls: ["https://bsc-dataseed.binance.org/"],
      },
    },
    avalanche: {
      providers: ethProviders,
      symbol: "AVAX",
      icon: "./assets/coins/currencies/c-avax.svg",
      opts: {
        chainName: "Avalanche Network",
        chainId: 43114,
        rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
      },
    },
    "boba-eth": {
      providers: ethProviders,
      symbol: "BOBA",
      icon: "./assets/coins/currencies/c-boba.svg",
      opts: {
        chainName: "BOBA L2",
        chainId: 288,
        rpcUrls: ["https://mainnet.boba.network"],
      },
    },
    boba: {
      providers: ethProviders,
      symbol: "BOBA",
      icon: "./assets/coins/currencies/c-boba.svg",
      opts: {
        chainName: "BOBA L2",
        chainId: 288,
        rpcUrls: ["https://mainnet.boba.network"],
      },
    },
    near: {
      providers: ["wallet.near.org"],
      symbol: "NEAR",
      icon: "./assets/coins/currencies/c-near.svg",
      opts: {
        networkId: "mainnet",
        keyStore: new keyStores.BrowserLocalStorageKeyStore(),
        nodeUrl: "https://rpc.mainnet.near.org",
        walletUrl: "https://wallet.mainnet.near.org",
        helperUrl: "https://helper.mainnet.near.org",
        explorerUrl: "https://explorer.mainnet.near.org",
      },
    },
    // arweave: {
    //   providers: ["Arconnect"],
    //   symbol: "AR",
    //   icon: "./assets/coins/currencies/AR.svg",
    //   opts: {}
    // },
    ethereum: {
      providers: ethProviders,
      symbol: "ETH",
      icon: "./assets/coins/currencies/ETH.svg",
      opts: {
        chainName: "Ethereum",
        chainId: 1
      }
    }
  } as any;




  /**
   * initialises the selected provider/currency
   * @param cname currency name
   * @param pname provider name
   * @returns
   */
  const initProvider = async () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (provider) {
      setProvider(undefined);
      setBundler(undefined);
      setAddress(undefined);
      return;
    }

    const pname = selection as string;
    const cname = currency as string;
    const p = providerMap[pname]; // get provider entry
    const c = currencyMap[cname];
    console.log(`loading: ${pname} for ${cname}`);
    const providerInstance = await p(c.opts).catch((e: Error) => {
      toast({
        status: "error",
        title: `Failed to load provider ${pname}`,
        duration: 10000,
      });
      console.log(e);
      return;
    });
    setProvider(providerInstance);
    console.log(`loaded ${pname} for ${cname}`);
  };

  const initBundlr = async () => {
    let bundlr;
    try {
      bundlr = new WebBundlr(bundlerHttpAddress, currency, provider, {
        providerUrl: rpcUrl,
        contractAddress,
      });
    } catch (e: any) {
      const eStr = e.toString();
      if (eStr.includes("dev/testnet")) return toast({ status: "error", title: "Custom RPC required", description: "To use devnet, you need to provide a devnet/testnet RPC. To set a custom RPC, use the configuration menu in the top-right corner." });
      return toast({ status: "error", title: "Failed to initialise Bundlr", description: eStr });
    }
    if (bundlerHttpAddress.includes("devnet.bundlr.network") && bundlr.currencyConfig.inheritsRPC) {
      setChainChange(false);
      toast({ status: "info", title: "Using Devnet", description: "Make sure you have connected to the correct network in your wallet - if not, change it and reconnect." });
    } try {
      // Check for valid bundlr node
      await bundlr.utils.getBundlerAddress(currency);
    } catch {
      toast({
        status: "error",
        title: `Failed to connect to bundlr ${bundlerHttpAddress}`,
        duration: 10000,
      });
      return;
    }
    try {
      await bundlr.ready();
    } catch (err) {
      console.log(err);
    } //@ts-ignore
    if (!bundlr.address) {
      console.log("something went wrong");
    }
    toast({ status: "success", title: `Connected to ${bundlerHttpAddress}` });
    setAddress(bundlr?.address);
    setBundler(bundlr);
  };

  const toProperCase = (s: string) => {
    return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();
  };

  const refreshBalance = async () => {
    address &&
      bundler!
        .getBalance(address)
        // @ts-ignore
        .then((res: BigNumber) => {
          setBalance(res.toString());
        });
    await toggleRefresh();
  };

  useEffect(() => {
    if (bundler) {
      refreshBalance();
    }
  }, [address, bundler, refreshBalance]);

  useEffect(() => {
    handlePrice();
  }, [imgStream, handlePrice]);

  const toggleRefresh = async () => {
    if (intervalRef) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = window.setInterval(async () => {
      bundler
        ?.getLoadedBalance()
        .then((r) => {
          setBalance(r.toString());
        })
        .catch((_) => clearInterval(intervalRef.current));
    }, 5000);
  };

  // parse decimal input into atomic units
  const parseInput = (input: string | number) => {
    const conv = new BigNumber(input).multipliedBy(
      bundler!.currencyConfig.base[1]
    );
    if (conv.isLessThan(1)) {
      toast({ status: "error", title: `Value too small!` });
      return;
    }
    return conv;
  };

  return (
    <>
      <a
        href="https://docs.bundlr.network"
        target="_blank"
        rel={"noreferrer"}
      >
        <HStack
          background={"black"}
          px={4}
          py={3}
          textColor="whiteAlpha.800"
          justifyContent={"center"}
        >
          <HiMegaphone />{" "}
          <Text>Click here to check out our brand new documentation!</Text>
        </HStack>
      </a>
      <Box position={"relative"} mt={10}>
        <HStack top={16} left={6} position={"fixed"}>
          <Icon as={BundlrIcon} h={12} w={12} />
        </HStack>

        <AdvancedOptionsModal
          button={
            <Button
              onClick={() => {
                setDevMode(!devMode);
              }}
              position="fixed"
              top={16}
              right={10}
              background="black"
              color="whiteAlpha.800"
              borderRadius="full"
            >
              <ImCogs />
            </Button>
          }
        >
          <Box p={6}>
            <Text>Advanced Overrides</Text>
            <small>(Only change if you know what you're doing!)</small>
            <VStack mt={4}>
              <Input
                value={rpcUrl}
                onChange={(evt: React.BaseSyntheticEvent) => {
                  setRpcUrl(evt.target.value);
                }}
                placeholder="RPC Url"
              />
              <Input
                value={contractAddress}
                onChange={(evt: React.BaseSyntheticEvent) => {
                  setContractAddress(evt.target.value);
                }}
                placeholder="Contract address"
              />
              <Button
                onClick={() => setChainChange(!chainChange)}
                width="full"
                colorScheme={chainChange ? "red" : "green"}
              >
                {chainChange ? "Disable" : "Enable"} Chain Changing
              </Button>
            </VStack>
          </Box>
        </AdvancedOptionsModal>

        <Container maxW={"container.md"} mt={[0, 40, 10]} position="relative">
          {/* <div
            style={{
              backgroundImage: "url(./assets/blur.svg)",
              backgroundPosition: "50% 50%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              WebkitFilter: 'blur(7.5em) blur(5px)',
              willChange: 'filter',
              filter: "blur(7.5em) blur(5px)",
              position: "absolute",
              top: 0,
              left: 0,
              height: "396px",
              width: "396px",
              opacity: 0.7,
            }}
          /> */}

          <VStack>
            {Object.keys(currencyMap).includes(currency) && (
              <TokenSelectModal
                callback={(v: string) => {
                  clean();
                  setCurrency(v);
                }}
                tokens={currencyMap}
              >
                <Box
                  border={"1px"}
                  borderStyle={"dashed"}
                  borderColor="gray.400"
                  cursor={"pointer"}
                  rounded={"md"}
                  width="full"
                  _hover={{
                    borderColor: "gray.700",
                    borderStyle: "solid",
                  }}
                >
                  <HStack
                    gap={3}
                    alignItems={"center"}
                    justifyContent={"center"}
                    width={"full"}
                    px={6}
                    py={4}
                  >
                    <Avatar size={"sm"} src={currencyMap[currency].icon} />
                    <Box>
                      <Text>{currency}</Text>
                      <Text
                        fontSize={"2xs"}
                        color={"gray.600"}
                        letterSpacing={"wider"}
                      >
                        {currencyMap[currency].symbol}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </TokenSelectModal>
            )}
            {!Object.keys(currencyMap).includes(currency) && (
              <Menu>
                <TokenSelectModal
                  callback={(v: string) => {
                    clean();
                    setCurrency(v);
                  }}
                  tokens={currencyMap}
                >
                  <Box
                    border={"1px"}
                    borderStyle={"dashed"}
                    borderColor="gray.400"
                    cursor={"pointer"}
                    rounded={"md"}
                    width="full"
                    _hover={{
                      borderColor: "gray.700",
                      borderStyle: "solid",
                    }}
                  >
                    <HStack
                      gap={3}
                      alignItems={"center"}
                      justifyContent={"center"}
                      width={"full"}
                      px={6}
                      py={4}
                    >
                      <Avatar size={"sm"} />
                      <Box>
                        <Text>No Token Selected</Text>
                        <Text
                          fontSize={"2xs"}
                          color={"gray.600"}
                          letterSpacing={"wider"}
                        >
                          N/A
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </TokenSelectModal>
              </Menu>
            )}

            <WalletModal callback={(v: string) => {
              setSelection(v);
            }} tokens={
              currencyMap[currency] ? currencyMap[currency].providers : []
            }>
              <Button
                width="full"
                bgColor={"black"}
                _hover={{ bgColor: "black" }}
                _active={{ bgColor: "black", ring: "2px" }}
                textColor={"whiteAlpha.800"}
                disabled={currency === defaultCurrency}
              >
                {selection ? selection : "Select Wallet"}
              </Button>
            </WalletModal>

            {/* <Menu>
              <MenuButton
                disabled={currency === defaultCurrency}
                as={Button}
                // rightIcon={<ChevronDownIcon />}
                width="full"
                bgColor={"black"}
                _hover={{ bgColor: "black" }}
                _active={{ bgColor: "black", ring: "2px" }}
                textColor={"whiteAlpha.800"}
              >
                {selection}
              </MenuButton>
              <MenuList>
                {Object.keys(providerMap).map((v) => {
                  return currencyMap[currency] &&
                    currencyMap[currency].providers.indexOf(v) !== -1 ? (
                    <MenuItem key={v} onClick={() => setSelection(v)}>
                      {v}
                    </MenuItem>
                  ) : undefined;
                })}
              </MenuList>
            </Menu> */}
            <Button
              disabled={
                !(
                  selection !== defaultSelection &&
                  currency !== defaultCurrency &&
                  bundlerHttpAddress.length > 8
                )
              }
              onClick={async () => await initProvider()}
              width="full"
              colorScheme={provider ? "red" : ""}
              bgColor={provider ? "red.600" : "black"}
              _hover={{ bgColor: provider ? "red.600" : "black" }}
              _active={{ bgColor: provider ? "red.600" : "black", ring: "2px" }}
              textColor={"whiteAlpha.800"}
            >
              {provider ? "Disconnect" : "Connect"}
            </Button>
          </VStack>
          {/* <Text>Connected Account: {address ?? "None"}</Text> */}
          {/* {true && ( */}

          {provider && (
            <VStack gap={4} mt={4} width="full">
              <HStack width={"full"}>
                <Input
                  value={bundlerHttpAddress}
                  onChange={updateAddress}
                  placeholder="Bundler Address"
                  width={"full"}
                />
                <Button
                  disabled={!provider}
                  onClick={async () => await initBundlr()}
                  bgColor={"black"}
                  _hover={{ bgColor: "black" }}
                  _active={{ bgColor: "black", ring: "2px" }}
                  textColor={"whiteAlpha.800"}
                >
                  Connect
                </Button>
              </HStack>

              {/* {true && ( */}
              {bundler && (
                <>
                  <HStack width="full">
                    {/* <Button
                      w={"310px"}
                      onClick={async () => {
                        address &&
                          bundler!
                            .getBalance(address)
                            .then((res: BigNumber) => {
                              setBalance(res.toString());
                            });
                        await toggleRefresh();
                      }}
                      bgColor={"black"}
                      _hover={{ bgColor: "black" }}
                      _active={{ bgColor: "black", ring: "2px" }}
                      textColor={"whiteAlpha.800"}
                    >
                      Get {toProperCase(currency)} Balance
                    </Button> */}

                    <Box width={"full"}>
                      <Tooltip
                        label={`(${balance} ${bundler?.currencyConfig.base[0]})`}
                      >
                        <Input
                          value={
                            balance
                              ? `${toProperCase(
                                currency
                              )} Balance: ${bundler?.utils
                                .unitConverter(balance)
                                .toFixed(7, 2)
                                .toString()} ${bundler?.currencyConfig.ticker.toLowerCase()}`
                              : "Your balance is loading..."
                          }
                          width="full"
                        />
                      </Tooltip>
                    </Box>
                  </HStack>
                  <HStack width={"full"}>
                    <Button
                      w={"310px"}
                      onClick={fund}
                      bgColor={"black"}
                      _hover={{ bgColor: "black" }}
                      _active={{ bgColor: "black", ring: "2px" }}
                      textColor={"whiteAlpha.800"}
                    >
                      Fund Bundlr
                    </Button>
                    <Input
                      placeholder={`${toProperCase(currency)} Amount`}
                      value={fundAmount}
                      onChange={updateFundAmount}
                      width="full"
                    />
                  </HStack>
                  <HStack width={"full"}>
                    <Button
                      w={"310px"}
                      onClick={withdraw}
                      bgColor={"black"}
                      _hover={{ bgColor: "black" }}
                      _active={{ bgColor: "black", ring: "2px" }}
                      textColor={"whiteAlpha.800"}
                    >
                      Withdraw Balance
                    </Button>
                    <Input
                      placeholder={`${toProperCase(currency)} Amount`}
                      value={withdrawAmount}
                      onChange={updateWithdrawAmount}
                      width="full"
                    />
                  </HStack>
                  <Box
                    onClick={handleFileClick}
                    py={"7"}
                    border="dashed"
                    width={"full"}
                    textAlign={"center"}
                    borderColor={imgStream ? "green.200" : "gray.300"}
                    textColor={imgStream ? "green.600" : "gray.600"}
                    borderWidth={2}
                    cursor="pointer"
                  >
                    <b>
                      {imgStream
                        ? `Selected ${(imgStream as any).name}`
                        : "Select file from Device"}
                    </b>
                  </Box>
                  {imgStream && (
                    <VStack width={"full"} mb="10">
                      {/* <Button
                        onClick={handlePrice}
                        width="full"
                        bgColor={"black"}
                        _hover={{ bgColor: "black" }}
                        _active={{ bgColor: "black", ring: "2px" }}
                        textColor={"whiteAlpha.800"}
                      >
                        Get Price
                      </Button> */}

                      {price && (
                        <Text py={4}>{`Cost: ${bundler?.utils
                          .unitConverter(price)
                          .toString()} ${bundler?.currencyConfig.ticker.toLowerCase()} `}</Text>
                      )}

                      <Button
                        onClick={uploadFile}
                        colorScheme="green"
                        width="full"
                        marginTop="0"
                        paddingY="30px"
                        fontWeight={"black"}
                      >
                        Upload to Bundlr
                      </Button>

                      <Box h="10" w="full" />
                    </VStack>
                  )}
                </>
              )}
              {totalUploaded && (
                <>
                  <p>
                    Upload progress:{" "}
                    {((totalUploaded / (size ?? 0)) * 100).toFixed()}%
                  </p>
                </>
              )}
            </VStack>
          )}
        </Container>
      </Box>
    </>
  );
}

export default App;
