import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure
} from '@chakra-ui/react';
import { SVGProps, cloneElement, useEffect, useState } from 'react';

export type SelectedTokenProps = {
  name: string;
  icon: string;
  symbol: string;
  wallets: string[];
};

export type AllowedTokensProps = {
  [key: string]: {
    icon: string;
    symbol: string;
    wallets: string | string[];
  };
};

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
    />
  </svg>
);

const useWalletModal = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch('');
  }, [isOpen]);

  return {
    isOpen,
    onClose,
    onOpen,
    search,
    setSearch
  };
};

const WALLETS = {
  "wallet.near.org": {
    "name": "NEAR Wallet",
    "icon": "/assets/wallets/near.png",
  },
  "MetaMask": {
    "name": "Metamask",
    "icon": "/assets/wallets/metamask.png",

  },
  "WalletConnect": {
    "name": "WalletConnect",
    "icon": "/assets/wallets/wallet-connect.png",

  },
  "Phantom": {
    "name": "Phantom",
    "icon": "/assets/wallets/phantom.png",

  },
  "Arconnect": {
    "name": "Arconnect",
    "icon": "/assets/wallets/arconnect.png"
  }
};

const WalletModal = ({
  callback,
  tokens,
  children
}: {
  callback: (v: string) => void;
  children: React.ReactElement;
  tokens: { [key: string]: any; };
}) => {
  const { isOpen, onClose, onOpen, search, setSearch } = useWalletModal();

  return (
    <>
      {cloneElement(children, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.500" backdropFilter="blur(3px)" />
        <ModalContent background={'transparent'} shadow={'none'}>
          <ModalBody padding={0}>
            <HStack py={2} px={4} background="white" rounded="full">
              <Icon as={SearchIcon} h={6} w={6} />
              <Input
                placeholder="Search for wallet name"
                border={'none'}
                focusBorderColor={'transparent'}
                onChange={(e) => setSearch(e.target.value)}
              />
            </HStack>
            <VStack mt={10} background="white" rounded={'md'} overflow="hidden" overflowY={'scroll'}>
              {Object.keys(WALLETS)
                .filter((key) => {
                  if (tokens.length) return tokens.includes(key);
                  return key.toLowerCase().includes(search.toLowerCase()) || tokens.includes(key);
                })
                .sort((a, b) => {
                  return a.toLowerCase().localeCompare(b.toLowerCase());
                })
                .map((token: any) => (
                  <Flex
                    gap={3}
                    alignItems={'center'}
                    key={token}
                    width={'full'}
                    px={6}
                    py={4}
                    _hover={{
                      background: 'gray.100'
                    }}
                    onClick={() => {
                      callback(token as any);
                      onClose();
                    }}
                  >
                    {/* @ts-ignore */}
                    <Avatar src={WALLETS[token].icon} size={'sm'} />
                    <Box>
                      <Text>{token}</Text>
                    </Box>
                  </Flex>
                ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WalletModal;
