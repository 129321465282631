import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { cloneElement, useEffect, useState } from "react";

export type SelectedTokenProps = {
    name: string;
    icon: string;
    symbol: string;
    wallets: string[];
};

export type AllowedTokensProps = {
    [key: string]: {
        icon: string;
        symbol: string;
        wallets: string | string[];
    };
};

const useTokenSelectModal = () => {
    const { isOpen, onClose, onOpen } = useDisclosure();

    const [search, setSearch] = useState("");

    useEffect(() => {
        setSearch("");
    }, [isOpen]);

    return {
        isOpen,
        onClose,
        onOpen,
        search,
        setSearch,
    };
};

const AdvancedOptionsModal = ({
    children,
    button,
}: {
    button: React.ReactElement;
    children: any;
}) => {
    const { isOpen, onClose, onOpen } = useTokenSelectModal();

    return (
        <>
            {cloneElement(button, { onClick: onOpen })}

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg="blackAlpha.500" backdropFilter="blur(3px)" />
                <ModalContent background={"white"} shadow={"none"}>
                    <ModalBody padding={0}>{children}</ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AdvancedOptionsModal;
